import type { RouteObject } from 'react-router';
import { DocumentSign } from '../pages/DocumentSign';
import { DocumentStatus } from '../pages/DocumentStatus';

export const routes: RouteObject[] = [
  {
    path: '/document/sign',
    element: <DocumentSign />
  },
  {
    path: '/document/status',
    element: <DocumentStatus />
  },
  {
    path: '*',
    element: <div>Page Not Found</div>
  }
];
