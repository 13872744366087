import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 66px)',
    marginTop: 66,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contract: {
    marginTop: 24,
    marginBottom: 200,
    padding: '28px',
    width: 'calc(540pt + 56px)',
    boxSizing: 'border-box',
    // '& div': {
    //   width: '100%',
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'center'
    // },
    // '& table': {
    //   width: '100% !important',
    // }
  },
  footer: {
    width: '100%',
    position: 'fixed',
    left: 0,
    bottom: 0,
    backgroundColor: "#F2F2F2",
    paddingTop: "24px"
  },
  footerContent: {
    backgroundColor: "white"
  }
}));

export default useStyles;
