import { useState, useEffect, useRef } from 'react';
import {
  Card,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@material-ui/core';
import { Header } from '../../components/Header';
import useStyles from './styles';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { DocumentResponse } from '../../interfaces/document';
import { AlertMessage } from '../../components/AlertMessage';
import DocumentApi from '../../domain/Document';
import { Loading } from '../../components/Loading';
import { replaceHtmlWithSchema } from '../../utils/replaceHtml';
import { useQuery } from '../../hooks/useQuery';

export function DocumentStatus() {
  const styles = useStyles();
  const navigate = useNavigate();
  const { documentParam, signerParam } = useQuery();

  const signatureDivRef = useRef<null | HTMLDivElement>(null);

  const [html, setHtml] = useState('');
  const [document, setDocument] = useState<DocumentResponse>({} as DocumentResponse);
  const [isDocumentNotFound, setIsDocumentNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  function backToTop() {
    signatureDivRef.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    });
  }

  function handleNavigateToDocument() {
    navigate(`/document/sign/?${window.location.href.split('?')[1]}`);
  }

  function translateStatus() {
    if (!document.signerDocument.isSigned) {
      return 'Progress';
    } else if (!document.signerDocument.userAcceptance) {
      return 'Declined';
    } else if (document.signerDocument.userAcceptance) {
      return 'Accept';
    } else {
      return 'Progress';
    }
  }

  async function getDocument() {
    try {
      if (documentParam && signerParam) {
        const response = await DocumentApi.get(documentParam, signerParam);

        const { htmlTemplate } = response.data.esignDocument;
        const { fullName, customValues, isSigned } = response.data.signerDocument;

        const { replaceHtml } = replaceHtmlWithSchema({
          html: htmlTemplate,
          replaceFields: true,
          customFields: !!customValues
            ? typeof customValues == 'string'
              ? JSON.parse(customValues)
              : {}
            : {},
          signature: isSigned ? fullName : ''
        });

        setDocument(response.data);
        setHtml(replaceHtml);
      } else {
        setIsDocumentNotFound(true);
      }
    } catch (error) {
      setIsDocumentNotFound(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentParam, signerParam]);

  if (loading) {
    return <Loading />;
  }

  if (isDocumentNotFound) {
    return (
      <AlertMessage title="Document Not Found" message="Sorry, this document does not exist." />
    );
  }

  return (
    <div>
      <Header
        title=""
        buttonTitle={document.signerDocument.isSigned ? '' : 'Sign Now'}
        handleClick={handleNavigateToDocument}
      />
      <div className={styles.container}>
        <Card className={styles.header} ref={signatureDivRef}>
          <div>
            <Typography variant="subtitle2" className={styles.status}>
              {translateStatus()}
            </Typography>
            <Typography variant="h5">{document.esignDocument.title}</Typography>
          </div>
          <Typography variant="subtitle2">
            Created on {dayjs(document.esignDocument.createdAt).format('MMM DD YYYY')}
          </Typography>
        </Card>
        <Card className={styles.aboutDocument}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Recipient</TableCell>
                <TableCell>Signed At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {document.signerDocument && (
                <TableRow hover>
                  <TableCell>{document.signerDocument?.fullName}</TableCell>
                  <TableCell>
                    {document.signerDocument.isSigned &&
                      dayjs(document.signerDocument.signatureDate).format('MMM DD YYYY')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
        <Card className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: html }} />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '24px'
          }}
        >
          <Button variant="text" color="primary" onClick={backToTop}>
            Back to top
          </Button>
        </div>
      </div>
    </div>
  );
}
