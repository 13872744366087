import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
interface BtnProps extends ButtonProps {
  children: React.ReactNode;
  loading?: boolean;
}

export function Btn({ children, loading = false, ...rest }: BtnProps) {
  return (
    <Button
      variant="contained"
      style={{ cursor: 'pointer' }}
      disabled={rest.disabled || loading}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={20} thickness={5} style={{ color: 'gray' }} />
      ) : (
        children
      )}
    </Button>
  );
}
