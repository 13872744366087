import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    borderBottom: '.0625rem solid #a8a8b3',
    zIndex: 999,
  },
  content: {
    overflow: 'hidden',
    background: 'white',
    height: '4rem',
    display: 'flex',
    padding: '0 1.25rem',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div': {
      display: 'flex',
      gap: '1.5rem',
      '& img': {
        [theme.breakpoints.down('xs')]: {
          display: 'none'
        }
      }
    }
  }
}));

export default useStyles;
