import { useLocation } from 'react-router';

export function useQuery() {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  return {
    documentParam: urlParams.get('d'),
    signerParam: urlParams.get('s')
  };
}
