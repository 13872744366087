import { Typography, Fade } from '@material-ui/core';
import dayjs from 'dayjs';

interface SignatureProps {
  isVisible: boolean;
  name: string;
  ip: string;
}

export function Signature({ ip, isVisible, name }: SignatureProps) {
  const when = dayjs(new Date()).format('MMMM D, YYYY [at] HH:MM A');
  const displayIp = `Ip Address: ${ip}`;

  return (
    <Fade in={isVisible} unmountOnExit>
      <div>
        <Typography
          variant="h3"
          style={{
            fontFamily: 'Parisienne',
            textTransform: 'capitalize',
            marginBottom: 4
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="caption"
          style={{ textTransform: 'uppercase', fontFamily: 'Courier New' }}
        >
          Signed electronically on {when}
          <br />
          {ip && displayIp}
        </Typography>
      </div>
    </Fade>
  );
}
