const defaultHostname: string = 'https://documentdev.kornerstonecredit.com';

const SETTINGS = {
  localhost: {
    //documentApi: "https://documentapidev.kornerstonecredit.com"
    documentApi: 'http://localhost:8001',
    utilityApi: 'https://utilityapidev.kornerstonecredit.com'
  },
  'documentdev.kornerstonecredit.com': {
    documentApi: 'https://documentapidev.kornerstonecredit.com',
    utilityApi: 'https://utilityapidev.kornerstonecredit.com'
  },
  'documentstg.kornerstonecredit.com': {
    documentApi: 'https://documentapistg.kornerstonecredit.com',
    utilityApi: 'https://utilityapistg.kornerstonecredit.com'
  },
  'document.kornerstonecredit.com': {
    documentApi: 'https://documentapi.kornerstonecredit.com',
    utilityApi: 'https://utilityapi.kornerstonecredit.com'
  }
};

const { hostname } = window.location;
const { documentApi, utilityApi } =
  SETTINGS[hostname as keyof typeof SETTINGS] || SETTINGS[defaultHostname as keyof typeof SETTINGS];

export const ApiEndpoints = {
  ESIGN_GET_DOCUMENT: `${documentApi}/Esign/GetEsignDocument`,
  ESIGN_SAVE_SIGNED: `${documentApi}/Esign/SaveSignedInformation`,
  GET_IP: `${utilityApi}/Ip/GetPublicIp`
};
