import { useState, useEffect } from 'react';

interface PageSize {
  width: number;
  height: number;
}

const usePageSize = (): PageSize => {
  const [pageSize, setPageSize] = useState<PageSize>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setPageSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.unobserve(document.documentElement);
    };
  }, []);

  return pageSize;
};

export default usePageSize;