import dayjs from 'dayjs';

interface MakeInputProps {
  type: string;
  isRequired: boolean;
  placeholder: string;
  name: string;
  value: string;
  replaceField: boolean;
  signature: string;
}

interface ReplaceHtmlWithSchemaProps {
  html: string;
  replaceFields?: boolean;
  signature?: string;
  customFields?: any;
}

function makeInput({
  name,
  isRequired,
  placeholder,
  type,
  value,
  replaceField,
  signature
}: MakeInputProps) {
  function labelWithValue(label: string) {
    return `<span style="color: #000; font-weight: bold; font-size: 9pt">${
      label === undefined ? '' : label
    }</span>`;
  }

  function inputField() {
    return `<input 
         required="${isRequired}"
         style="padding: 4px; outline: none;"
         name="${name}" 
         placeholder="${placeholder}" 
         id="${name}"
         ${
           value &&
           name !== '[text|req|signer1|Account Name|account_name|Integ SystemName]' &&
           `value="${value}"`
         }
      />`;
  }

  function checkbox() {
    return `<input type="checkbox" />`;
  }

  if (type === 'date') {
    return labelWithValue(dayjs(new Date()).format('MM/DD /YYYY'));
  } else if (replaceField && type === 'text') {
    return labelWithValue(value);
  } else if (replaceField && type === 'sig') {
    return labelWithValue(signature);
  } else if (type === 'text') {
    return inputField();
  } else if (type === 'check') {
    return checkbox();
  } else {
    return '';
  }
}

export function replaceHtmlWithSchema({
  html,
  signature = '',
  replaceFields = false,
  customFields = {}
}: ReplaceHtmlWithSchemaProps) {
  let replaceHtml = html;
  const inputListeners: string[] = [];

  const contentHtmlInLine = html;

  const schema: RegExpExecArray[] = [];

  var regex = /\[(.*?)\]/g;

  var match;
  while (match = regex.exec(contentHtmlInLine)) {
      schema.push(match); 
  }
  schema.forEach((params) => {
    //exemple params: ["params with types, required, placeholder, name and value with brackets", "[", "params with types, required, placeholder, name and value with no round brackets", "]"]
    const paramsInput = params[1].split('|');
    //0: type
    //1: isReq
    //3: Placeholder
    //4: name
    //5: value
    const input = makeInput({
      type: paramsInput[0],
      isRequired: paramsInput[1] === 'req' ? true : false,
      placeholder: paramsInput[3],
      name: params[0],
      value: replaceFields ? customFields[params[0]] : paramsInput[5],
      replaceField: replaceFields,
      signature: signature
    });

    replaceHtml = replaceHtml.replace(params[0].toString(), input);

    if (paramsInput[0] === 'text' && paramsInput[1] === 'req') {
      inputListeners.push(params[0]);
    }
  });

  return {
    replaceHtml,
    inputListeners
  };
}

export function addInputsListeners(inputListeners: string[]) {
  inputListeners.forEach((id) => {
    const input = document.getElementById(id) as HTMLInputElement;

    if (input) {
      if (input.value === '') {
        input.style.background = 'rgba(255, 255, 204, 0.9)';
        input.style.border = '1px solid red';
      } else {
        input.style.background = '#FFF';
        input.style.border = '1px solid #8FC31F';
      }

      input.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement;
        if (target.value === '') {
          input.style.background = 'rgba(255, 255, 204, 0.9)';
          input.style.border = '1px solid red';
        } else {
          input.style.background = '#FFF';
          input.style.border = '1px solid #8FC31F';
        }
      });
    }
  });
}

export function scrollIntoInput(inputListeners: string[], signatureFocus: () => void) {
  let isContinueListening = true;

  const input = document.querySelectorAll('input');

  if (inputListeners.length > 0) {
    input.forEach((input) => {
      if (input.value === '' && isContinueListening) {
        input.focus();
        isContinueListening = false;
      } else if (isContinueListening) {
        signatureFocus();
      }
    });
  } else {
    signatureFocus();
  }
}
