import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 4.125rem)',
    marginTop: '4.125rem',
    overflow: 'auto',
    padding: '0 15rem',
    [theme.breakpoints.only('md')]: {
      padding: '0 7.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.25rem'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5rem',
    margin: '1rem auto',
    marginBottom: '1rem',
    width: 'calc(540pt + 56px)',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      '& h6': {
        padding: '.5rem'
      }
    }
  },
  status: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '.25rem',
    color: '#FFF',
    textAlign: 'center',
    padding: '.5rem .25rem',
    maxWidth: '6.25rem',
  },
  aboutDocument: {
    display: 'flex',
    gap: '3rem',
    padding: '1.5rem',
    margin: 'auto',
    marginBottom: '1rem',
    width: 'calc(540pt + 56px)',
    '& div': {
      '& p': {
        marginBottom: '.5rem'
      }
    }
  },
  htmlContent: {
    margin: 'auto',
    padding: '28px',
    userSelect: 'none',
    width: 'calc(540pt + 56px)',
    boxSizing: 'border-box',
    position: 'relative',
    '& div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    marginBottom: '1.5rem'
  },
  backToTopButton: {}
}));

export default useStyles;
