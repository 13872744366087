import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#8FC31F",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contast with palette.primary.main
            contrastText: "#FFFFFF"
        },
        secondary: {
            // light: '#0066ff',
            main: "#86217F",
            // dark: will be calculated from palette.secondary.main,
            contrastText: "#FFFFFF"
        }
        // error: will use the default color
    }
});

export default theme;
