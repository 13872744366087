import { useState, useEffect, useRef, FormEvent } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography
} from '@material-ui/core';
import { Btn } from '../../components/Btn';
import { Header } from '../../components/Header';
import { Signature } from '../../components/Signature';
import useStyles from './styles';
import { DocumentResponse, SignDocumentRequest } from '../../interfaces/document';
import DocumentApi from '../../domain/Document';
import { Loading } from '../../components/Loading';
import UtilityApi from '../../domain/Utility';
import {
  addInputsListeners,
  replaceHtmlWithSchema,
  scrollIntoInput
} from '../../utils/replaceHtml';
import { useQuery } from '../../hooks/useQuery';
import { ApiEndpoints } from '../../utils/apiConfig';
import { api } from '../../services/api';
import { useNavigate } from 'react-router';
import { AlertMessage } from '../../components/AlertMessage';
import usePageSize from '../../hooks/usePageSize';

export function DocumentSign() {
  const styles = useStyles();
  const navigate = useNavigate();
  const { documentParam, signerParam } = useQuery();

  const signatureSectionRef = useRef<null | HTMLDivElement>(null);
  const signatureInputRef = useRef<null | HTMLInputElement>(null);
  const ip = useRef('');

  const [signatureOriginal, setSignatureOriginal] = useState('');

  const [document, setDocument] = useState<DocumentResponse>({} as DocumentResponse);
  const [html, setHtml] = useState<string>('');
  const [isAgreementAccept, setIsAgreementAccept] = useState(false);
  const [changeSignature, setChangeSignature] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputListeners, setInputListeners] = useState<string[]>([]);
  const [isDocumentNotFound, setIsDocumentNotFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const { width, height } = usePageSize();
  const [sizeDelta, setSizeDelta] = useState(0);

  useEffect(() => {
    var element = window.document.getElementById('child');
    var pageWidth = window.innerWidth - 24;
    var originalWidth = window.innerWidth;
    if (element && pageWidth < 748) {
      originalWidth = parseFloat(window.getComputedStyle(element).width);
    }

    var scale = pageWidth / originalWidth;
    setSizeDelta(scale);
  }, [width, height, document]);
  

  function handleScrollIntoElement() {
    function signatureFocus() {
      signatureSectionRef.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth'
      });
      signatureInputRef.current?.focus();
    }

    scrollIntoInput(inputListeners, signatureFocus);
  }

  function handleChangeSignature() {
    setChangeSignature('');
    setIsAgreementAccept(false);
  }

  async function handleOnSubmit(e: FormEvent, userAcceptance: boolean) {
    setIsSubmitting(true);
    let inputData = {};

    if (userAcceptance) {
      e.preventDefault();

      const formData = new FormData(e?.target as HTMLFormElement);
      inputData = Object.fromEntries(formData);
    }

    const data: SignDocumentRequest = {
      fullName:
        changeSignature === null || changeSignature === '' ? signatureOriginal : changeSignature,
      email: document.signerDocument.email,
      ipAddress: ip.current,
      userAcceptance: userAcceptance,
      signatureDate: new Date(),
      createdAt: document.signerDocument.createdAt,
      referenceId: document.esignDocument.referenceId,
      customValues: inputData,
      signingUrl: window.location.href,
      signerId: String(document.signerDocument.id),
      updatedAt: new Date()
    };

    try {
      const response = await api.post(ApiEndpoints.ESIGN_SAVE_SIGNED, data);
      const { metadata } = document.esignDocument;
      const { redirectUrl } = JSON.parse(metadata)

      if (response.status === 200) {
        if (redirectUrl) {
          return window.location = redirectUrl;
        } else {
          navigate(`/document/status/?${window.location.href.split('?')[1]}`);
        }
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function getDocument() {
    try {
      if (documentParam && signerParam) {
        const response = await DocumentApi.get(documentParam, signerParam);

        const { htmlTemplate } = response.data.esignDocument;
        const { fullName, isSigned } = response.data.signerDocument;

        if (isSigned) {
          return navigate(`/document/status/?${window.location.href.split('?')[1]}`);
        }

        const { replaceHtml, inputListeners } = replaceHtmlWithSchema({
          html: htmlTemplate
        });

        setDocument(response.data);
        setSignatureOriginal(fullName);
        setInputListeners(inputListeners);
        setHtml(replaceHtml);
      }
    } catch (error) {
      setIsDocumentNotFound(true);
    } finally {
      setLoading(false);
    }
  }

  async function GetIp() {
    const response = await UtilityApi.getIp();
    ip.current = response.data;
  }

  useEffect(() => {
    getDocument();
    GetIp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentParam, signerParam]);

  useEffect(() => {
    if (inputListeners.length > 0) {
      addInputsListeners(inputListeners);
    }
  }, [html, inputListeners]);

  if (loading) {
    return <Loading />;
  }

  if (isDocumentNotFound) {
    return (
      <AlertMessage title="Document Not Found" message="Sorry, this document does not exist." />
    );
  }

  return (
    <>
      <Header
        title={document.esignDocument?.title}
        buttonTitle="Sign"
        handleClick={handleScrollIntoElement}
      />
      <form 
        className={styles.container} 
        onSubmit={(e) => handleOnSubmit(e, true)} 
      >
        <Card 
          dangerouslySetInnerHTML={{ __html: html }} 
          className={styles.contract} 
          id='child'
          style={{
            transform: 'scale(' + sizeDelta + ')',
            position: 'relative',
            transformOrigin: 'top center'
          }} 
        />
        <Card component="section" className={styles.footer} >
          <CardContent className={styles.footerContent} ref={signatureSectionRef}>
            {changeSignature !== null && (
              <TextField
                id="signature"
                name="signature"
                label="Signature"
                margin="normal"
                helperText="Lease Signature"
                fullWidth
                value={changeSignature}
                onChange={(e) => setChangeSignature(e.target.value)}
                inputRef={signatureInputRef}
              />
            )}
            <Checkbox
              id="isAgreementAccept"
              value="isAgreementAccept"
              checked={isAgreementAccept}
              onChange={(e) => setIsAgreementAccept(e.target.checked)}
              style={{ paddingLeft: 0 }}
            />
            <Typography variant="caption">
              i understand that by typing my name and clicking on "I Accept". I am electronically
              signing this document
            </Typography>
            <Signature
              ip={ip.current}
              isVisible={isAgreementAccept}
              name={
                changeSignature === null || changeSignature === ''
                  ? signatureOriginal
                  : changeSignature
              }
            />
            <CardActions style={{ paddingLeft: 0 }}>
              <Btn
                disabled={!isAgreementAccept || isSubmitting || changeSignature === ''}
                loading={isSubmitting}
                color="primary"
                id="accept"
                name="accept"
                type="submit"
              >
                I Accept
              </Btn>

              <Btn loading={isSubmitting} color="secondary" onClick={handleChangeSignature}>
                Change signature
              </Btn>
            </CardActions>
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={isError}
          autoHideDuration={5000}
          transitionDuration={500}
          onClose={() => setIsError(false)}
          message="An error occurred while signing the document"
        >
          <SnackbarContent
            style={{ backgroundColor: '#d32f2f' }}
            message="An error occurred while signing the document"
          />
        </Snackbar>
      </form>
    </>
  );
}
