import { DocumentResponse } from './../../interfaces/document';
import { api } from '../../services/api';
import { ApiEndpoints } from '../../utils/apiConfig';

export default class DocumentApi {
  static get = async (document: string, signer: string) => {
    return await api.get<DocumentResponse>(`${ApiEndpoints.ESIGN_GET_DOCUMENT}`, {
      params: {
        d: document,
        s: signer
      }
    });
  };
}
