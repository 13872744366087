import { Box, Typography } from '@material-ui/core';
import { Btn } from '../Btn';
import useStyles from './styles';

interface HeaderProps {
  title: string;
  handleClick?: () => void;
  buttonTitle?: string;
}

export function Header({ title, buttonTitle = '', handleClick = () => {} }: HeaderProps) {
  const styles = useStyles();

  return (
    <Box component="header" className={styles.container}>
      <Box className={styles.content}>
        <Box>
          <img src="/kornerstone-credit.png" alt="logo" width="auto" height="33" />
          <Typography variant="h5">{title}</Typography>
        </Box>
        {buttonTitle && (
          <Btn type="button" color="primary" onClick={handleClick}>
            {buttonTitle}
          </Btn>
        )}
      </Box>
    </Box>
  );
}
