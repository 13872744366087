import { MuiThemeProvider } from '@material-ui/core';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { routes } from './routes/index.routes';
import theme from './styles/Theme';

import './styles/global.css';

const router = createBrowserRouter(routes);

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </MuiThemeProvider>
  );
}

export default App;
