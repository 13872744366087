import { Box, Typography } from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';

interface AlertMessageProps {
  title: string;
  message: string;
}

export function AlertMessage({ title, message }: AlertMessageProps) {
  return (
    <Box
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 14
      }}
    >
      <WarningOutlined style={{ width: 58, height: 58 }} />
      <Typography variant="h4">{title}</Typography>
      <Typography variant="subtitle2">{message}</Typography>
    </Box>
  );
}
