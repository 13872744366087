import { Box, CircularProgress } from '@material-ui/core';

export function Loading() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <CircularProgress size={32} thickness={5} />
    </Box>
  );
}
